.welcome_label {
  font-family: 'avelline';
  font-size: 150px !important;
}
@media only screen and (max-width: 600px) {
  .welcome_label {
    font-family: 'avelline';
    font-size: 100px !important;
  }
}

.image_1 {
  background-image: url('../../images/just-kei.jpg');
  height: 200px;
  //   width: 100%;
  background-size: contain;
  background-repeat: no-repeat;
}
.image_2 {
  background-image: url('../../images/just-me.jpg');
  height: 500px;
  width: 100%;
  background-size: contain;
  background-repeat: no-repeat;
}
