/* Webfont: glamourluxury-Round */
@font-face {
  font-family: 'glamourLuxuryRound';
  src: url('../../fonts/glamourluxury/glamourluxury-Bold.eot'); /* IE9 Compat Modes */
  src: url('../../fonts/glamourluxury/glamourluxury-Round.eot'); /* IE9 Compat Modes */
  src: url('../../fonts/glamourluxury/glamourluxury-Round.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('../../fonts/glamourluxury/glamourluxury-Round.woff')
      format('woff'),
    /* Modern Browsers */
      url('../../fonts/glamourluxury/glamourluxury-Round.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('../../fonts/glamourluxury/glamourluxury-Round.svg#glamourluxury-Round')
      format('svg'); /* Legacy iOS */
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

/* Webfont: glamourluxury-Thin */
@font-face {
  font-family: 'glamourLuxuryThin';
  src: url('../../fonts/glamourluxury/glamourluxury-Thin.eot'); /* IE9 Compat Modes */
  src: url('../../fonts/glamourluxury/glamourluxury-Thin.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('../../fonts/glamourluxury/glamourluxury-Thin.woff')
      format('woff'),
    /* Modern Browsers */
      url('../../fonts/glamourluxury/glamourluxury-Thin.ttf') format('truetype'),
    /* Safari, Android, iOS */
      url('../../fonts/glamourluxury/glamourluxury-Thin.svg#glamourluxury-Thin')
      format('svg'); /* Legacy iOS */
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}
/* Webfont: glamourluxury-Bold */
@font-face {
  font-family: 'glamourLuxury';
  src: url('../../fonts/glamourluxury/glamourluxury-Bold.eot'); /* IE9 Compat Modes */
  src: url('../../fonts/glamourluxury/glamourluxury-Bold.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('../../fonts/glamourluxury/glamourluxury-Bold.woff')
      format('woff'),
    /* Modern Browsers */
      url('../../fonts/glamourluxury/glamourluxury-Bold.ttf') format('truetype'),
    /* Safari, Android, iOS */
      url('../../fonts/glamourluxury/glamourluxury-Bold.svg#glamourluxury-Bold')
      format('svg'); /* Legacy iOS */
  font-style: normal;
  font-weight: bold;
  text-rendering: optimizeLegibility;
}
/* Webfont: glamourluxury-Regular */
@font-face {
  font-family: 'glamourLuxuryRegular';
  src: url('../../fonts/glamourluxury/glamourluxury-Regular.eot'); /* IE9 Compat Modes */
  src: url('../../fonts/glamourluxury/glamourluxury-Regular.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('../../fonts/glamourluxury/glamourluxury-Regular.woff')
      format('woff'),
    /* Modern Browsers */
      url('../../fonts/glamourluxury/glamourluxury-Regular.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('../../fonts/glamourluxury/glamourluxury-Regular.svg#glamourluxury-Regular')
      format('svg'); /* Legacy iOS */
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

/* Webfont: glamourluxury-Light */
@font-face {
  font-family: 'glamourLuxuryLight';
  src: url('../../fonts/glamourluxury/glamourluxury-Light.eot'); /* IE9 Compat Modes */
  src: url('../../fonts/glamourluxury/glamourluxury-Light.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('../../fonts/glamourluxury/glamourluxury-Light.woff')
      format('woff'),
    /* Modern Browsers */
      url('../../fonts/glamourluxury/glamourluxury-Light.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('../../fonts/glamourluxury/glamourluxury-Light.svg#glamourluxury-Light')
      format('svg'); /* Legacy iOS */
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

/* Webfont: Deluce */
@font-face {
  font-family: 'deluce';
  src: url('../../fonts/deluce/Deluce.otf') format('opentype'); /* IE9 Compat Modes */
  src: url('../../fonts/deluce/Deluce.ttf') format('truetype');
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

/* Webfont: Deluce-Italic */
@font-face {
  font-family: 'deluceItalic';
  src: url('../../fonts/deluce/Deluce-Italic.otf') format('opentype'); /* IE9 Compat Modes */
  src: url('../../fonts/deluce/Deluce-Italic.ttf') format('truetype');
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}
/* Webfont: Flex */
@font-face {
  font-family: 'flexLux';
  src: url('../../fonts/flex/flex.otf') format('opentype'); /* IE9 Compat Modes */
  src: url('../../fonts/flex/flex.ttf') format('truetype');
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

/* Webfont: Avelline */
@font-face {
  font-family: 'avelline';
  src: url('../../fonts/Avelline/Avelline.otf') format('opentype'); /* IE9 Compat Modes */
  src: url('../../fonts/Avelline/Avelline.ttf') format('truetype');
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

/* Webfont: Wyattrully */
@font-face {
  font-family: 'wyattruly';
  src: url('../../fonts/wyattruly/Wyattruly.otf') format('opentype'); /* IE9 Compat Modes */
  src: url('../../fonts/wyattruly/Wyattruly.ttf') format('truetype');
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

/* Webfont: maria */
@font-face {
  font-family: 'maria';
  src: url('../../fonts/Maria/Maria.otf') format('opentype'); /* IE9 Compat Modes */
  src: url('../../fonts/Maria/Maria.ttf') format('truetype');
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}
